import axios from 'config/axios';
import { SET_FILTERS } from './actionTypes';

export const setFilters = payload => (dispatch, getStore) => {
  const { filters } = getStore();
  const hasChanged = Object.keys(payload).find(key => {
    return filters[key] !== payload[key];
  });

  if (hasChanged) {
    dispatch(({ type: SET_FILTERS, payload }));
  }
};

export const setFilterData = payload => ({
  type: SET_FILTERS,
  payload,
});

export const fetchGroups = () => async dispatch => {
  try {
    const response = await axios.get('subjects?group-by=none');
    dispatch(setFilterData({ groups: response.data }));
  } catch (e) {
    console.error(e);
  }
};

export const fetchGrades = () => async dispatch => {
  try {
    const response = await axios.get('grades');
    dispatch(setFilterData({ gradesList: response.data }));
  } catch (e) {
    console.error(e);
  }
};

export const fetchLevels = () => async dispatch => {
  try {
    const response = await axios.get('levels');
    dispatch(setFilterData({ levels: response.data }));
  } catch (e) {
    console.error(e);
  }
};
