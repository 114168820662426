const styles = () => ({
  modal: {
    '@media (min-width: 768px)': {
      width: 490,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
    },
  },
  container: {
    width: '100%',
    '@media (min-width: 768px)': {
      height: 675,
    },
  },
  paper: {
    margin: 0,
    width: '100%',
    maxHeight: 'none',
    '@media (min-width: 768px)': {
      borderRadius: 10,
    },
  },
});

export default styles;
