import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/styles/withStyles';

import { FiltersForm } from 'tutortime-filters-library';
import styles from './styles';

const FiltersModal = props => {
  const {
    classes,
    open,
    onClose,
    theme,
    onSubmit = () => {},
    filters,
    variant = 'filters',
    tutor,
    forced,
    errors,
    disableDate = false,
  } = props;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      classes={{
        root: classes.modal,
        container: classes.container,
        paper: classes.paper,
      }}
    >
      <FiltersForm
        theme={theme}
        onSubmit={onSubmit}
        onClose={onClose}
        filters={filters}
        tutor={tutor}
        forced={forced}
        variant={variant}
        errors={errors}
        disableDate={disableDate}
        isModal
      />
    </Dialog>
  );
};

const mapStateToProps = ({ filters, tutors }) => ({
  filters,
  tutor: tutors.current,
});

export default connect(mapStateToProps)(withStyles(styles)(FiltersModal));
